:root {
  --background-primary-light: hsl(0, 0%, 100%);
  --background-secondary-light: hsl(220, 20%, 97%);
  --background-tertiary-light: hsl(220, 13%, 95%);
  --text-primary-light: #1d1d1f;
  --text-secondary-light: rgba(29, 29, 31, .6);
  --text-tertiary-light: rgba(29, 29, 31, .4);
  --text-callout: hsl(262, 75%, 52%);
  --text-header-size: 80px;
  --text-subtext-size: 25px;
  --text-footer-size: 16px;
  --searchbar-height: calc(var(--text-header-size) * 1.5);
  --max-view-width: 1520px;
  --grid-l: 30px;
  --grid-m: 20px;
  --grid-s: 10px;
}
@media(min-width: 929px) and (max-width: 1520px) {
  :root {
    --text-header-size: 50px;
    --text-subtext-size: 22px;
    --text-footer-size: 14px;
  }
}
@media(min-width: 575px) and (max-width: 950px) {
  :root {
    --text-header-size: 30px;
    --text-subtext-size: 14px;
    --text-footer-size: 12px;
  }
}
@media (max-width: 575px) {
  :root {
    --text-header-size: 20px;
    --text-subtext-size: 11px;
    --text-footer-size: 11px;
  }
}

@font-face {
  font-family: 'LabGrotesque-Light';
  src: url('./fonts/LabGrotesque/labgrotesque-light.ttf');
}

@font-face {
  font-family: 'LabGrotesque-Regular';
  src: url('./fonts/LabGrotesque/labgrotesque-regular.ttf');
}

@font-face {
  font-family: 'LabGrotesque-Medium';
  src: url('./fonts/LabGrotesque/labgrotesque-medium.ttf');
}

@font-face {
  font-family: 'LabGrotesque-Black';
  src: url('./fonts/LabGrotesque/labgrotesque-black.ttf');
}

@font-face {
  font-family: 'LabGrotesque-Bold';
  src: url('./fonts/LabGrotesque/labgrotesque-bold.ttf');
}

html, body {
  padding: 0;
  margin: 0;
  font-family: 'LabGrotesque-Regular', -apple-system, 'Segoe UI', 'Roboto', sans-serif;
  font-size: 100%;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
  overflow: hidden;
  user-select: none;

  color: var(--text-primary-light);
  background: linear-gradient(150deg, var(--background-secondary-light) 0%, var(--background-tertiary-light) 33%, var(--background-tertiary-light) 100%) 100% no-repeat;
}

body {
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

h1 {
  margin: 0;
  padding: 0;
  font-weight: normal;
  font-size: var(--text-header-size);
  line-height: 1;
  white-space: nowrap;
}
