#app-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.subtext {
	font-size: var(--text-subtext-size);
	color: var(--text-tertiary-light);
	padding-top: calc(var(--searchbar-height) / 3);
}
