#search-bar {
	background-color: var(--background-primary-light);
	border-radius: calc(var(--searchbar-height) / 2);
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.24);
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: var(--searchbar-height);
	overflow: hidden;
}
#search-bar h1 {
	margin-left: calc(var(--searchbar-height) / 4);
	/*margin-bottom: calc(var(--text-header-size) * .09);*/
}
#search-bar .h1-callout {
	margin-left: 0;
	margin-right: calc(var(--searchbar-height) / 6);
	color: var(--text-callout);
}