/*Make the footer at the bottom of the page with a margin-right of 20px */
#footer-container {
	position: absolute;
	bottom: 0;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: space-between;
	width: 100%;
	max-width: var(--max-view-width);
	margin-bottom: var(--grid-l);
	margin-left: var(--grid-l);
	margin-right: var(--grid-l);
	font-size: var(--text-footer-size);
	color: var(--text-tertiary-light);
}

.footer-column {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;
	margin-left: var(--grid-l);
	margin-right: var(--grid-l);
}